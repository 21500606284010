import React from "react";
import "./App.scss";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import Projects from "./components/Projects/Projects";
import { Route, Switch } from "react-router";
import Blogs from "./components/Blogs/Blogs";
import WallOfFame from "./components/WallOfFame/WallOfFame";

export default function App() {
  return (
    <div className="App">
      <div className="App__content">
        <Switch>
          <Route exact path="/">
            <Home />
            <Projects />
            <Footer />
          </Route>
          <Route path="/blogs" component={Blogs} />
          <Route path="/wall-of-fame" component={WallOfFame} />
        </Switch>
      </div>
    </div>
  );
}
