import React from "react";
import "./HocBlogs.scss";
import sprite from "../../UI/SVG/sprite.svg";
import { Fade } from "react-reveal";

function HocBlogs({ header, newProject, description, link }) {
  return (
    <Fade bottom>
      <div className="hocBlogs">
        <div className="hocBlogs__top">
          {newProject ? <h5 className="hocProject__top--new">NEW</h5> : null}
          <h1 className="hocBlogs__top--header">{header}</h1>
          <p className="hocBlogs__top--description">{description}</p>
        </div>
        <div className="hocBlogs__bottom">
          <a
            href={link}
            target="_blank"
            rel="noreferrer"
            className="hocBlogs__bottom--a"
          >
            <svg>
              <use href={sprite + "#icon-book"} />
            </svg>
            Check Blog!
          </a>
        </div>
      </div>
    </Fade>
  );
}

export default HocBlogs;
