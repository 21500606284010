import React from "react";
import HocProjects from "../../hoc/HocProjects/HocProjects";
import "./Projects.scss";

const Projects = () => {
  return (
    <div className="project">
      <h1 className="project__h1">Latest Projects</h1>
      <div className="project__content">
        <HocProjects
          header="Arc - open-sourced Notion"
          description="An open-sourced & self-hosted Notion alternative built completely from scratch - including authentication with JWT refresh & access tokens, & an editor using SlateJS"
          newProject="true"
          github="https://github.com/manthankumbhar/frontend_open-source-notion"
          website="https://open-source-notion.manthankumbhar.com/"
          blogs="true"
          bloglink="https://montyk.notion.site/Open-source-Notion-c53d7269b0ae4a89bdaed2c665cafdf9"
        />
        <HocProjects
          header="Melody Music Player"
          description="Melody music player is a music streaming platform with a goal to improve my full-stack skills! Build using React, Express, PostgreSQL, AWS S3"
          newProject="true"
          github="https://github.com/manthankumbhar/music-player-front"
          website="https://melodymusicplayer.netlify.app/"
          blogs="true"
          bloglink="https://montyk.notion.site/Melody-Music-Player-201ff189737c4f7295774b9b322e1d3c"
        />
        <HocProjects
          header="Github, Discord, API calls, and me"
          description="It's an integration that helps users to get notifications of their GitHub push and sends it to the discord servers as mentioned by them with a basic message format specifying the username, push message, and repo name. Build using React, Express, PostgreSQL."
          newProject="true"
          github="https://github.com/manthankumbhar/fiddling-with-github-discord-integration"
          website="https://github-discord-integration.netlify.app/"
          blogs="true"
          bloglink="https://montyk.notion.site/GitHub-Discord-API-calls-and-me-v3-0-29b398a05569441bbfd4510c5c47db45"
        />
        <HocProjects
          header="MyBurger App"
          description="Here you could build a delicious burger and order it to your address but it would never reach you anyways lol (coz it's a fun project), It's front-end is developed with React js and I've used Firebase for the realtime database of all orders!"
          github="https://github.com/manthankumbhar/myburger"
          website="https://myburger-react.netlify.app/"
        />
        <HocProjects
          header="Natours"
          description="Natours is a pure CSS based project made along a course with an agenda to increase my skills in CSS and SASS"
          github="https://github.com/manthankumbhar/natours"
          website="https://monty-natours.netlify.app/"
        />
        <HocProjects
          header="Sigh Bot"
          description="It's a basic discord bot - Sigh bot. The bot helps you sigh and is triggered via different words! It is hosted via replit and I've used a tool known as uptimerobot which keeps the replit alive and doesn't let it sleep"
          github="https://replit.com/@manthankumbhar/Discordbot1"
          website="https://sigh-bot.netlify.app/"
          blogs="true"
          bloglink="https://montyk.notion.site/Building-a-Discord-bot-with-js-e7652e37374d4b11ba9286bb95e05526"
        />
        <HocProjects
          header="Trillo"
          description="Trillo is also a CSS based project made along a course with an agenda to increase my skills in Flexbox"
          github="https://github.com/manthankumbhar/trillo"
          website="https://monty-trillo.netlify.app/"
        />
        <HocProjects
          header="Kiran Vadhaiya and associates"
          description="Next is a client-based website that I made for Kiran Vadhaiya and associates, Kiran Vadhaiya is an Interior Designer. I've optimized the website and the website performance metrics according to GTmetrix is a grade 'A'."
          github="https://github.com/manthankumbhar/kva"
          website="https://kva.netlify.app/"
        />
        <HocProjects
          header="Nexter"
          description="Nexter is another CSS based project made along a course with an agenda to increase my skills in grid CSS"
          github="https://github.com/manthankumbhar/nexter"
          website="https://monty-nexter.netlify.app/"
        />
        <HocProjects
          header="Auth-demo-firebase"
          description="Next is Auth-demo-firebase, just a trial for learning authentication using firebase. The UI is good because I had designed and developed it for some other product/client but the client dropped that idea later on."
          github="https://github.com/manthankumbhar/auth-demo-firebase"
          website="https://auth-demo-firebase.netlify.app/"
        />
        <HocProjects
          header="Sneha's Yogalaya"
          description="It's a Yoga website that I gifted my mom on her birthday. It's a basic website with HTML, CSS, and JS and I've also used bootstrap for a better UI!"
          github="https://github.com/manthankumbhar/sneha-yogalaya"
          website="https://sneha-yogalaya.netlify.app/"
        />
        <HocProjects
          header="Todo-list"
          description="I started up with a Todo-list which is a very easy web app made with ReactJS, and CSS :)"
          github="https://github.com/manthankumbhar/todolist"
          website="https://todolistbymanthan.netlify.app/"
        />
        <HocProjects
          header="Test aws s3"
          description="It's a dummy project - test AWS s3, I've built it to learn how AWS s3 works and have created a basic server that sends an image/ song to the s3 bucket and returns the link to view the file uploaded to s3."
          github="https://github.com/manthankumbhar/test-aws-s3"
          website="https://test-aws-s3.netlify.app/"
          blogs="true"
          bloglink="https://montyk.notion.site/Test-aws-s3-4bff7c721c09458e8943b5a5cdeb55c0"
        />
      </div>
    </div>
  );
};

export default Projects;
