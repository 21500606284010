import React from "react";
import HocBlogs from "../../hoc/HocBlogs/HocBlogs";
import "./Blogs.scss";

function Blogs() {
  return (
    <div className="blogs">
      <h1 className="blogs__h1">Latest Blogs</h1>
      <div className="blogs__content">
        <HocBlogs
          header="Arc - open-sourced Notion"
          newProject="true"
          description="An open-sourced & self-hosted Notion alternative built completely from scratch - including authentication with JWT refresh & access tokens, & an editor using SlateJS"
          link="https://montyk.notion.site/Open-source-Notion-c53d7269b0ae4a89bdaed2c665cafdf9"
        />
        <HocBlogs
          header="Melody Music Player"
          newProject="true"
          description="Melody music player is a music streaming platform with a goal to improve my full-stack skills! Build using React, Express, PostgreSQL, AWS S3"
          link="https://montyk.notion.site/Melody-Music-Player-201ff189737c4f7295774b9b322e1d3c"
        />
        <HocBlogs
          header="Test aws s3"
          description="It's a dummy project - test AWS s3, I've built it to learn how AWS s3 works and have created a basic server that sends an image/ song to the s3 bucket and returns the link to view the file uploaded to s3."
          link="https://montyk.notion.site/montyk/Test-AWS-S3-4bff7c721c09458e8943b5a5cdeb55c0"
        />
        <HocBlogs
          header="Github, Discord, API calls, and me v3.0"
          description="The v3.0 includes the addition of a brand new feature ie authentication! Build using React, Express, and PostgreSQL"
          link="https://montyk.notion.site/GitHub-Discord-API-calls-and-me-v3-0-29b398a05569441bbfd4510c5c47db45"
        />
        <HocBlogs
          header="Github, Discord, API calls, and me v2.0"
          description="The v2.0 includes the addition of new features and a great frontend to add your discord webhooks directly and get the link to paste in your GitHub webhooks. Build using React and Express"
          link="https://montyk.notion.site/montyk/GitHub-Discord-API-calls-and-me-v2-0-b6e539ab36614c5d8aa395ddb231ecba"
        />
        <HocBlogs
          header="Github, Discord, API calls, and me v1.0"
          description="It's an integration that helps users to get notifications of their GitHub push and sends it to the discord servers as mentioned by them with a basic message format specifying the username, push message, and repo name. Build using React & Express"
          link="https://montyk.notion.site/montyk/GitHub-Discord-API-calls-and-me-98c1d8adae004ac9ad1ed4636ee74d64"
        />
        <HocBlogs
          header="Sigh Bot"
          description="It's a basic discord bot - Sigh bot. The bot helps you sigh and is triggered via different words! It is hosted via replit and I've used a tool known as uptimerobot which keeps the replit alive and doesn't let it sleep"
          link="https://montyk.notion.site/montyk/Building-a-Discord-bot-with-js-e7652e37374d4b11ba9286bb95e05526"
        />
      </div>
      <p className="blogs__end">
        Wanna check more info? <a href="/">Go to home</a>
      </p>
    </div>
  );
}

export default Blogs;
