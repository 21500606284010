import React from "react";
import "./Home.scss";
import me from "../../UI/me4.jpg";
import { Fade } from "react-reveal";
import Typist from "react-typist";

const Home = () => {
  return (
    <div className="home">
      <Fade left>
        <div className="home__left">
          <h1 className="home__left--h1">
            <Typist avgTypingDelay={120} cursor={{ show: false }}>
              Hello,{" "}
              <span className="home__left--span">I'm Manthan Kumbhar</span>
            </Typist>
          </h1>
          <h4 className="home__left--h4">Full Stack Web Developer</h4>
          <p className="home__left--p">
            At 20, I'm a self-taught coder, Frontend Developer @{" "}
            <a href="https://f22labs.com" target="_blank" rel="noreferrer">
              F22 Labs
            </a>
            , and am currently building an open-sourced Notion. I've worked on a
            Music streaming platform and a Github-Discord sync in the past.
            <br />
            Worked around Javascript, React, Next, Tailwind, Node, Express,
            Python, Flask, PostgreSQL, Html, CSS, SASS, Flexbox, Grid CSS, and
            Bootstrap.
          </p>
        </div>
      </Fade>
      <Fade right>
        <div className="home__right">
          <img className="home__right--img" src={me} alt="me" />
        </div>
      </Fade>
    </div>
  );
};

export default Home;
