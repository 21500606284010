import React from "react";
import "./HocProjects.scss";
import sprite from "../../UI/SVG/sprite.svg";
import { Fade } from "react-reveal";
import Tooltip from "@material-ui/core/Tooltip";

const HocProjects = ({
  header,
  newProject,
  description,
  github,
  website,
  blogs,
  bloglink,
}) => {
  return (
    <Fade bottom>
      <div className="hocProject">
        <div className="hocProject__top">
          {newProject ? <h5 className="hocProject__top--new">NEW</h5> : null}
          <h1 className="hocProject__top--header">{header}</h1>
          <p className="hocProject__top--description">{description}</p>
        </div>
        <div className="hocProject__bottom">
          {blogs ? (
            <a href={bloglink} target="_blank" rel="noreferrer">
              <Tooltip
                title={
                  <h5 style={{ fontSize: "1.4rem", height: "1.2rem" }}>Blog</h5>
                }
                arrow
              >
                <svg>
                  <use href={sprite + "#icon-book"} />
                </svg>
              </Tooltip>
            </a>
          ) : null}
          <a href={github} target="_blank" rel="noreferrer">
            <Tooltip
              title={
                <h5 style={{ fontSize: "1.4rem", height: "1.2rem" }}>Github</h5>
              }
              arrow
            >
              <svg>
                <use href={sprite + "#icon-github"} />
              </svg>
            </Tooltip>
          </a>
          <a href={website} target="_blank" rel="noreferrer">
            <Tooltip
              title={
                <h5 style={{ fontSize: "1.4rem", height: "1.2rem" }}>
                  Live Project
                </h5>
              }
              arrow
            >
              <svg>
                <use href={sprite + "#icon-arrow-up-right2"} />
              </svg>
            </Tooltip>
          </a>
        </div>
      </div>
    </Fade>
  );
};

export default HocProjects;
