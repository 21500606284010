import React, { useCallback, useState } from "react";
import "./WallOfFame.scss";
import img1 from "../../UI/WallOfFame/1.png";
import img2 from "../../UI/WallOfFame/2.png";
import img3 from "../../UI/WallOfFame/3.png";
import img4 from "../../UI/WallOfFame/4.png";
import img5 from "../../UI/WallOfFame/5.png";
import img6 from "../../UI/WallOfFame/6.png";
import img7 from "../../UI/WallOfFame/7.png";
import img8 from "../../UI/WallOfFame/8.png";
import img9 from "../../UI/WallOfFame/9.png";

export default function WallOfFame() {
  const [src, setSrc] = useState(null);

  const HocBtn = useCallback((number, img) => {
    return (
      <button className="wallOfFame__btn" onMouseOver={() => setSrc(img)}>
        {number}
      </button>
    );
  }, []);
  return (
    <div className="wallOfFame">
      <h1 className="wallOfFame__h1">Wall of fame</h1>
      <div className="wallOfFame__tv">
        {src !== null ? <img src={src} alt="review pic" /> : null}
      </div>
      <div>
        {HocBtn(1, img1)}
        {HocBtn(2, img2)}
        {HocBtn(3, img3)}
        {HocBtn(4, img4)}
        {HocBtn(5, img5)}
        {HocBtn(6, img6)}
        {HocBtn(7, img7)}
        {HocBtn(8, img8)}
        {HocBtn(9, img9)}
      </div>
    </div>
  );
}
