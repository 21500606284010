import React from "react";
import "./Footer.scss";
import {
  FaGithub,
  FaTwitter,
  FaInstagram,
  FaStackOverflow,
  FaLinkedin,
  FaDiscord,
} from "react-icons/fa";
import { SiNotion } from "react-icons/si";
import Tada from "react-reveal/Tada";

const Footer = () => {
  return (
    <div className="footer">
      <h1 className="footer__header">Let's Get In Touch</h1>
      <p className="footer__p">
        Currently open to internship opportunities and working on open source
        projects if time permits, If you have questions for me or just want to
        say hello/ catchup on a call then click on the buttons below and I'll
        get back to you soon 🙂
      </p>
      <Tada>
        <a href="mailto:namaste@manthankumbhar.com" className="footer__hello">
          <div className="footer__hello">Say hello 👋</div>
        </a>
        <a
          href="https://calendly.com/manthankumbhar"
          className="footer__hello"
          target="_blank"
          rel="noreferrer"
        >
          <div className="footer__hello">Let's catch up on a call 📞</div>
        </a>
      </Tada>
      <div>
        <a
          href="https://www.linkedin.com/in/manthankumbhar/"
          className="footer__link"
          target="_blank"
          rel="noreferrer"
        >
          <FaLinkedin className="footer__link--logo" />
        </a>
        <a
          href="https://github.com/manthankumbhar"
          className="footer__link"
          target="_blank"
          rel="noreferrer"
        >
          <FaGithub className="footer__link--logo" />
        </a>
        <a
          href="https://stackoverflow.com/users/14394762/manthankumbhar"
          className="footer__link"
          target="_blank"
          rel="noreferrer"
        >
          <FaStackOverflow className="footer__link--logo" />
        </a>
        <a
          href="https://montyk.notion.site/montyk/My-Notions-bd03b6f3ca054b229614f689b4302740"
          className="footer__link"
          target="_blank"
          rel="noreferrer"
        >
          <SiNotion className="footer__link--logo" />
        </a>
        <a
          href="https://twitter.com/manthan_kumbhar"
          className="footer__link"
          target="_blank"
          rel="noreferrer"
        >
          <FaTwitter className="footer__link--logo" />
        </a>
        <a
          href="https://www.instagram.com/manthankumbhar/"
          className="footer__link"
          target="_blank"
          rel="noreferrer"
        >
          <FaInstagram className="footer__link--logo" />
        </a>
        <a
          href="https://discord.com/users/562686629387698180"
          className="footer__link"
          target="_blank"
          rel="noreferrer"
        >
          <FaDiscord className="footer__link--logo" />
        </a>
        <p className="footer__copyright">
          Developed by <a href="/">Manthan Kumbhar</a> &copy;{" "}
          {new Date().getFullYear()} (All Rights Reserved)
        </p>
      </div>
    </div>
  );
};

export default Footer;
